import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import { Link as ScrollLink } from "react-scroll"
import { IconContext } from "react-icons"
import { FaLine } from "react-icons/fa"
import { VscCallOutgoing } from "react-icons/vsc"
import { AiOutlineForm } from "react-icons/ai"
import { Element } from "react-scroll"


const IconLine = () => (
  <IconContext.Provider value={{ color: "white", size: "30px" }}>
    <FaLine />
  </IconContext.Provider>
)

const IconCall = () => (
  <IconContext.Provider value={{ color: "white", size: "25px" }}>
    <VscCallOutgoing />
  </IconContext.Provider>
)

const IconForm = () => (
  <IconContext.Provider value={{ color: "white", size: "25px" }}>
    <AiOutlineForm />
  </IconContext.Provider>
)


const scrollAtt = {
  activeClass: "active",
  spy: true,
  smooth: true,
  offset: -50,
  duration: 300
}

const StyledWrap = styled.div`
  width:90%;
  margin:20px auto 50px;
      ${() => media.tablet(css`
      max-width:700px;
      .first-p{
        padding:50px 0;
      }
    `
)}
`

const StyledList = styled.div`
  width:100%;
  display:flex;
  flex-flow:column nowrap;
  align-items:center;
  justify-content:center;
  margin:30px auto;
  div{
    display:flex;
    flex-flow:row nowrap;
    align-items:center;
    justify-self:center;
    a{
      width:55px;
      height:55px;
      border-radius:60px;
      background:${({ theme }) => theme.colors.primary.beige};
      display:flex;
      flex-flow:row nowrap;
      align-items:center;
      justify-content:center;
      margin:0 10px;
      cursor:pointer;
    }
  }
  a{
    margin:40px auto;
    text-decoration:underline;
    cursor:pointer;
  }
`

const StyledQA = styled.div`
  width:100%;
  .qaSec{
    padding:30px 0;
    h5{
      font-weight:bold;
      margin:.62em 0;
      color:${({ theme }) => theme.colors.primary.beige};
    }
    p.ansDet{
      margin-top:1.62em;
      font-size:.8em;
      color:#dadada;
    }
  }
`

const LeadLines = () => {
  const data = useStaticQuery(query)
  return (
    <StyledWrap>
      <p className="first-p">ご予約、お問合せは「HotPepperBeauty」、「お電話」にて承ります。</p>
      <StyledList>
        <div>
          <a data-cline='cline' href="https://lin.ee/alreHXF" target="_blank" rel="noopener noreferrer"><IconLine /></a>
          <a data-ctel='ctel' href="tel:03-6662-7281"><IconCall /></a>
          <ScrollLink to="form" {...scrollAtt}><IconForm /></ScrollLink>
        </div>
        <ScrollLink to="faq" {...scrollAtt}>よくあるご質問</ScrollLink>
      </StyledList>
      <StyledQA>
        <Element name="faq" />
        {data.zoo.qandaSection.map((panda, index) => (
          <div className="qaSec" key={index}>
            <h5>{panda.questionText}</h5>
            <p dangerouslySetInnerHTML={{ __html: panda.answerText }} />
            <p className="ansDet" dangerouslySetInnerHTML={{ __html: panda.answerTextDetails }} />
          </div>
        ))}
      </StyledQA>
    </StyledWrap>
  )
}

export const query = graphql`
  {
    zoo:strapiFaqPage {
      pageTitle
      qandaSection {
        answerText
        answerTextDetails
        questionText
      }
      qaMediaAlt
      qaMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lineMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export default LeadLines
