import React, { useEffect, useRef, useState } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import Recaptcha from "react-recaptcha"
import styled, { css } from "styled-components"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import TextField from "@material-ui/core/TextField"
import AdapterDateFns from "@material-ui/lab/AdapterDateFns"
import LocalizationProvider from "@material-ui/lab/LocalizationProvider"
import TimePicker from "@material-ui/lab/TimePicker"

const StyledWrap = styled.div`
  width: 100%;
  h3 {
    font-size: 1.32em;
    margin-bottom: 1.62em;
  }
  form {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    input,
    textarea {
      background: transparent;
      border: 1px solid rgba(200, 200, 200, 0.5);
      border-radius: 3px;
      padding: 0.12em;
    }
    label {
      width: 100%;
      margin-top: 1.62em;
      input,
      textarea {
        width: 100%;
        margin-top: 0.62em !important;
        &:focus {
          background: ${({ theme }) => theme.colors.primary.beige};
          color: #292929;
        }
      }
    }
  }
  #submit {
    border: none;
    background: ${({ theme }) => theme.colors.primary.red};
    text-align: center;
    width: 150px;
    height: 30px;
    margin: 20px auto;
    border-radius: 0px;
    box-shadow: 10px 10px 0px #3a3a3a;
    transition: 0.6s;
    &:hover {
      box-shadow: 3px 3px 0px #dadada;
    }
  }
`

// const StyledTimePicker = styled(TimePicker)`
//   background: red;
//   .MuiClock-pin {
//     background: black;
//   }
// `

const StyledTextField = styled(TextField)`
  border: 1px solid rgba(200,200,200,0.5);
  border-radius: 3px;
  padding: .12em
  transition: 0.6s;
  input {
    background: transparent;
    border: 1px solid rgba(200, 200, 200, 0.5);
    border-radius: 3px;
    padding: 0.12em;
    color: #fff;
  }
  button {
    color: #fff;
  }

  label {
    color: #fff;
  }
`

const messages = {
  string: "文字列を入力してください",
  required: "必ず入力してください",
  phone: "電話番号を入力してください",
  email: "メールアドレスを入力してください",
  time: "時間の形式で入力してください",
}

const schema = yup.object().shape({
  cname: yup.string(messages.string).required(messages.required),
  phone: yup
    .string(messages.string)
    .required(messages.required)
    .matches(/^0(\d{1,4}-\d{1,4}-\d{4}$|\d{9,10}$)/, {
      excludeEmptyString: true,
      message: messages.phone,
    }),
  email: yup
    .string(messages.string)
    .required(messages.required)
    .email(messages.email),
  inquiries: yup.string(messages.string).required(messages.required),
})

const ContactForm = ({ notify }) => {
  const { errors, handleSubmit, register, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  })
  const [dateText, setDateText] = useState("")
  const [timeValue, setTimeValue] = useState(null)
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)

  const handleDateChange = event => {
    setDateText(event.target.value)
  }

  useEffect(() => {
    register("appointDate")
    register("appointTime")
  }, [register])

  useEffect(() => {
    setValue("appointDate", dateText)
  })

  useEffect(() => {
    setValue("appointTime", timeValue)
  }, [timeValue])

  // Handle Recaptcha v3 & submitting form
  let recaptchaInstance
  const executeCaptcha = event => {
    event.preventDefault()
    recaptchaInstance.execute()
  }

  // const verifyCallback = async response => {}

  const onSubmit = async (data, token) => {
    setSubmitButtonDisabled(true)
    data["cname"] = data["cname"].trim()

    if (data["appointTime"]) {
      data["appointTime"] = `${data["appointTime"].getHours()}:${data[
        "appointTime"
      ].getMinutes()}`
    }
    data["token"] = token

    const result = await axios({
      method: "post",
      url: `${process.env.STRAPI_API_URL}/email-send`,
      data,
    }).catch(err => {})
    console.log(result.data)
    // Display validation error message
    if (result.data.status === "validationError") {
      result.data.validationResults.forEach(res => {
        !res.isValid && notify.error(res.message)
      })
      return
    }

    if (result.data.status === "fail") {
      setSubmitButtonDisabled(true)
      return notify.error(result.data.message)
    }

    if (result.data.ResForAdmin.status === "success") {
      notify.success(result.data.ResForAdmin.message)
      setSubmitButtonDisabled(false)
      reset({})
    }
  }

  const onError = (errors, event) => {
    console.log("errors", errors)
  }

  return (
    <>
      <StyledWrap>
        <h3>お問い合わせフォーム</h3>
        <form onSubmit={executeCaptcha}>
          <label>
            <span>お名前（必須）</span>
            <input name="cname" type="text" required ref={register} />
            <p>{errors.cname?.message}</p>
          </label>
          <label>
            <span>電話番号（必須）</span>
            <input name="phone" type="tel" required ref={register} />
            <p>{errors.phone?.message}</p>
          </label>
          <label>
            <span>メールアドレス（必須）</span>
            <input name="email" type="email" required ref={register} />
            <p>{errors.email?.message}</p>
          </label>
          <label>
            <span>お問い合わせ内容（必須）</span>
            <textarea name="inquiries" required ref={register} />
            {/* {errors.inquiries && <p>{errors.inquiries?.message}</p>} */}
            <p>{errors.inquiries?.message}</p>
          </label>
          <label>
            <span>ご希望のメニュー</span>
            <input name="menu" type="text" ref={register} />
          </label>
          <label>
            <span>ご希望の日</span>
          </label>
          <StyledTextField
            id="date"
            label=""
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={dateText}
            onChange={handleDateChange}
          />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <label>
              <span>ご希望の時間</span>
            </label>

            <TimePicker
              label=""
              ampm={false}
              value={timeValue}
              onChange={newtimeValue => {
                setTimeValue(newtimeValue)
              }}
              renderInput={params => {
                return <StyledTextField {...params} required={false} />
              }}
            />
          </LocalizationProvider>

          <input
            id="submit"
            type="submit"
            value="送信"
            disabled={submitButtonDisabled}
          />
        </form>
        <Recaptcha
          ref={e => {
            recaptchaInstance = e
          }}
          sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
          type="reCAPTCHA v3"
          size="invisible"
          verifyCallback={handleSubmit(onSubmit)}
        />
      </StyledWrap>
    </>
  )
}

export default ContactForm
