import React from "react"
import styled, { css } from "styled-components/"
import { media } from "../styles/media"
import BackImage from "../images/backgroundBlack.png"
import Image from "gatsby-image"
import { ToastContainer, toast, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Element } from "react-scroll"


// components
import InquiryForm from "../components/InquiryForm"
import Layout from "../components/Layout"
import { useStaticQuery, graphql } from "gatsby"
import LeadLine from "../components/LeadLine"
import ContactForm from "../components/ContactForm"
import SEO from '../components/SEO'

const StyledToastContainer = styled(ToastContainer).attrs({})`
  .Toastify__toast-container {
    z-index: 999;
  }
  .Toastify__toast-body {
    color: #fff;
  }
`
const StyledWrap = styled.div`
  width: 100%;
  position: relative;
`
const StyledBack = styled.div`
  position: absolute;
  background: url(${BackImage});
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
`
const StyledContents = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 50px;
`
const StyledTop = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  .gatsby-image-wrapper {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
    &:after {
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      z-index: 0;
      position: absolute;
    }
  }
  .h2 {
    position: relative;
  }
`

const StyledForm = styled.div`
  width: 90%;
  max-width: 500px;
  margin: auto;
  padding: 50px 0;
`

const notify = {
  success: message => {
    toast.success(message)
  },
  error: message => {
    toast.error(message)
  },
}

const Inquiry = () => {
  const data = useStaticQuery(query)

  const jsonld = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${data.seo.pagesSeo[0].pageUrl}`,
          "name": `${data.seo.pagesSeo[0].pageTitle}`,
          "image": `${data.seo.pagesSeo[0].pageMedia.absolutePath}`
        }
      }, {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${data.seo.pagesSeo[4].pageUrl}`,
          "name": `${data.seo.pagesSeo[4].pageTitle}`,
          "image": `${data.seo.pagesSeo[4].pageMedia.absolutePath}`
        }
      }]
    }
  ]
  return (
    <Layout>
      <SEO
        title={data.seo.pagesSeo[4].pageTitle}
        description={data.seo.pagesSeo[4].pageDescript}
        jsonld={jsonld}
        pageUrl={data.seo.pagesSeo[4].pageUrl}
        type='article'
        imageUrl={data.seo.pagesSeo[4].pageMedia.absolutePath}
      />
      <StyledWrap>
        <StyledBack />
        <StyledContents>
          <StyledTop>
            <h2>{data.zoo.pageTitle}</h2>
            <Image
              fluid={data.zoo.qaMedia.childImageSharp.fluid}
              alt={data.zoo.qaMediaAlt}
            />
          </StyledTop>
          <LeadLine />
        </StyledContents>
      </StyledWrap>

      <Element name="form" />
      <StyledToastContainer
        position="bottom-center"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
      />
      <StyledForm>
        <ContactForm notify={notify} />
      </StyledForm>
    </Layout>
  )
}

export const query = graphql`
  {
    zoo: strapiFaqPage {
      pageTitle
      qandaSection {
        answerText
        answerTextDetails
        questionText
      }
      qaMediaAlt
      qaMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lineMedia {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    seo: strapiSeo {
      pagesSeo {
        pageDescript
        id
        pageTitle
        pageUrl
        pageMedia {
          absolutePath
        }
      }
      personName
      organizeName
      logoImage {
        absolutePath
      }
    }
  }
`

export default Inquiry
